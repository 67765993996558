import React, { useState, useEffect, useCallback, FC } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { fetcher } from "~/lib/fetcher";
import useSWR from "swr";
import { Prompt, ActionButton, ActionButtons } from "../FlowBuilder/StepCanvas/StepCanvas.styles";

import { MediaPlayer } from "~/components/UI";
import {
  isContentStep,
  isRoleRandomiserStep,
  isRoleRotationStep,
  isChoicesStep,
  isGoToStep,
  isBranchStep,
  isIframeStep,
  isMarkStepComplete,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
} from "~/lib/type_guards";
import { UpperArea, CenterArea, BottomArea } from "../FlowBuilder/StepCanvas/StepCanvas.styles";
import { ChoicesList } from "../FlowBuilder/StepCanvas/ChoicesStep";
import { ROLE_A, ROLE_B, EVERYONE_ELSE, ROLES } from "~/lib/defaults";

import { countActions, countDone, scaledDuration } from "./step_utils";
import LiquidRender from "./LiquidRender";
import {
  DEFAULT_CHOICE_BUTTON_HEIGHT,
  DEFAULT_CHOICE_ACTION,
  DEFAULT_CHOICE_COLUMNS,
  DEFAULT_CHOICE_LIST_WIDTH,
} from "~/lib/defaults";
import { textForLocale } from "~/lib/textForLocale";
import WaitSuggestion from "./WaitSuggestion";
import { ScoreWrapper } from "../FlowBuilder/StepCanvas/FeedbackScoreStep";
import { Card, CardBody, TextInput } from "grommet";

const FlowPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #333;
  overflow-y: hidden;
`;

const StateBox = styled.div`
  display: flex;
  height: 100%;

  width: 100%;
  flex: 0;
  position: relative;
  overflow-y: scroll;

  pre {
    width: 50%;
    color: #fff;
    max-height: 100%;
    font-size: 12px;
    line-height: 1.2em;
  }
`;

const PreviewToolbox = styled.div`
  background-color: #242222;
  border-bottom: 2px solid #555;
  height: 25px;
  padding: 10px 20px;
  color: #fff;

  align-content: center;
  justify-content: space-between;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  font-size: 14px;

  a {
    color: #fff;
  }

  a:visited {
    color: #fff;
  }
`;

const ToolSection = styled.div`
  width: 33%;
`;

const UsersCountInput = styled.input`
  width: 40px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #333;
  color: #fff;
  padding: 0 5px;
  margin: 0 5px;
  font-size: 14px;
`;

const PreviewArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  padding: 10px 20px 20px 10px;

  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 100%;
  height: calc(100% - 29px);

  box-sizing: border-box;
`;

const UserView = styled.div`
  background-color: #555;
  border-radius: 8px;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex: 0;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
`;

const StyledActionButton = styled(ActionButton)`
  &.picked {
    background-color: #333;
    border-color: #333;

    &:hover {
      border-color: #333;
      box-shadow: 0px 0px 0px 2px rgba(255, 161, 0, 0.2);
    }
  }
`;

const ScoreDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export interface StepState {
  [userID: number]: string | boolean;
}
export interface User {
  id: number;
  name: string;
  role: Roles;
}

const FlowPreview: FC = () => {
  const params = useParams();

  const { data: flow } = useSWR<FlowIndexResponse>(
    `${process.env.FLOW_BUILDER_API_URL}/flows/${params.flowID}`,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: true,
    }
  );

  const locale = params.locale as string;

  const your_partners = {
    en: "your partners",
    es: "sus compañeros",
    pt: "seus companheiros",
    fr: "vos partenaires",
    de: "ihre partner",
    sv: "dina partners",
    it: "i tuoi partner",
  } as LocaleText;

  const and = {
    en: "and",
    es: "y",
    pt: "e",
    fr: "et",
    de: "und",
    sv: "och",
    it: "e",
  } as LocaleText;

  const roleAssignment = (userIndex: number) => {
    if (userIndex === 0) {
      return ROLE_A;
    } else if (userIndex === 1) {
      return ROLE_B;
    } else {
      return EVERYONE_ELSE;
    }
  };

  const nameAssignment = (userIndex: number) => {
    if (userIndex === 0) {
      return "Oliver";
    } else if (userIndex === 1) {
      return "Thais";
    } else if (userIndex === 2) {
      return "Edric";
    } else {
      return "Participant " + (userIndex + 1);
    }
  };

  const [audioContext, setAudioContext] = useState<{
    audioContext: AudioContext | undefined;
    gainNode: GainNode | undefined;
    silentNode: GainNode | undefined;
  }>({ audioContext: undefined, gainNode: undefined, silentNode: undefined });
  const [flowState, setFlowState] = useState<StepState[]>([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [playAudio, setPlayAudio] = useState(false);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [timeOnStep, setTimeOnStep] = useState(0);
  const [timeOnSection, setTimeOnSection] = useState(0);

  const storedUserCount = sessionStorage.getItem("userCount");
  const [userCount, setUserCount] = useState(storedUserCount ? parseInt(storedUserCount) : 3);
  const [users, setUsers] = useState<User[]>([
    ...Array.from(Array(userCount).keys()).map((i) => ({
      id: i + 1,
      name: nameAssignment(i),
      role: roleAssignment(i),
    })),
  ]);

  const stepID = params.stepID && parseInt(params.stepID);
  const sectionID = params.sectionID && parseInt(params.sectionID);
  const stepIndex =
    stepID && flow && (flow.sections.flatMap((s) => s.steps).findIndex((s) => s.id === stepID) as number);
  const sectionIndex = sectionID && flow && flow.sections.findIndex((s) => s.id === sectionID);
  const sectionStepIndex =
    sectionIndex &&
    flow &&
    flow.sections[sectionIndex].steps.length > 0 &&
    flow.sections.slice(0, sectionIndex).reduce((acc, s) => acc + s.steps.length, 0);

  const flattenedSteps: AnyStep[] = [];

  // userResponses is an object that contains the responses users have given to
  // steps, indexable by the steps title, and the users current role.
  // i.e.: {"Please select a card": {"A": "1", "B": "3"}}
  const userResponses: {
    [stepTitle: string]: {
      [userRole in Roles]: string | boolean;
    };
  } = {};

  if (flow) {
    let index = 0;
    flow.sections.forEach((section) => {
      section.steps.forEach((step) => {
        const stepWithSection = Object.assign({}, { sectionTitle: textForLocale(section.title, locale) }, step);
        flattenedSteps.push(stepWithSection);

        ROLES.forEach((role) => {
          const userWithRole = users.find((u) => u.role === role);
          if (userWithRole && flowState[index]) {
            userResponses[textForLocale(step.title, locale)] = userResponses[textForLocale(step.title, locale)] || {};
            userResponses[textForLocale(step.title, locale)][role] = flowState[index][userWithRole.id];
          }
        });

        index++;
      });
    });
  }

  const currentStep = flattenedSteps[currentStepIndex];

  useEffect(() => {
    window.sessionStorage.setItem("userCount", users.length.toString());
  }, [users]);

  useEffect(() => {
    const step = flattenedSteps[stepIndex || 0];
    if (isBranchStep(step) || isMarkStepComplete(step)) {
      if (step.settings.stepID) {
        const newStepIndex = flow?.sections.flatMap((s) => s.steps).findIndex((s) => s.id === step.settings.stepID);
        setCurrentStepIndex(newStepIndex || 0);
      } else {
        setCurrentStepIndex(0);
      }
    } else if (isRoleRotationStep(step)) {
      setCurrentStepIndex((stepIndex as number) - 1 || 0);
    } else {
      setCurrentStepIndex(stepIndex || sectionStepIndex || 0);
    }
  }, [stepIndex, sectionStepIndex]);

  useEffect(() => {
    setTimeOnSection(0);

    const interval = setInterval(() => {
      setTimeOnSection((timeOnSection) => timeOnSection + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStep?.sectionTitle]);

  useEffect(() => {
    if (currentSection && timeOnSection > currentSection.durationSeconds && currentSection.enforceDuration) {
      const currentSectionIndex = flow?.sections.findIndex((s) => s.title["en"] === currentStep.sectionTitle);

      if (typeof currentSectionIndex !== "undefined" && currentSectionIndex > -1) {
        const nextSection = flow?.sections[currentSectionIndex + 1];

        if (nextSection) {
          const nextSectionTitle = nextSection.title["en"];
          const firstStepOfSection = flattenedSteps.findIndex((s) => s.sectionTitle === nextSectionTitle);
          setCurrentStepIndex(firstStepOfSection);
        }
      }
    }
  }, [timeOnSection]);

  useEffect(() => {
    tick(currentStepIndex);
    setAudioCurrentTime(0);
    setTimeOnStep(0);

    const interval = setInterval(() => {
      setTimeOnStep((timeOnStep) => timeOnStep + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStepIndex]);

  // advanceOneStep advances the step index by one, but avoids going beyond
  // the amount of actual steps
  const advanceOneStep = (stepIndex: number) => {
    setCurrentStepIndex(Math.min(flattenedSteps.length - 1, stepIndex + 1));
  };

  // advanceTwoSteps advances the step index by two, but avoids going beyond
  // the amount of actual steps
  const advanceTwoSteps = (stepIndex: number) => {
    setCurrentStepIndex(Math.min(flattenedSteps.length - 1, stepIndex + 2));
  };

  const randomiseRoles = () => {
    const randomRoleStartIndex = Math.floor(Math.random() * users.length);

    users[randomRoleStartIndex].role = ROLE_A;
    users[(randomRoleStartIndex + 1) % users.length].role = ROLE_B;

    users.forEach((user, i) => {
      if (i !== randomRoleStartIndex && i !== (randomRoleStartIndex + 1) % users.length) {
        user.role = EVERYONE_ELSE;
      }
    });

    setUsers(users);
  };

  const rotateRoles = () => {
    const currentRoleStartIndex = users.findIndex((u) => u.role === ROLE_A);
    const nextRoleStartIndex = (currentRoleStartIndex + 1) % users.length;

    const newUsers = users.map((u, i) => {
      let assign = { role: EVERYONE_ELSE };

      ROLES.forEach((role, roleIndex) => {
        if (roleIndex > users.length - 1) return; // Don't try to assign more roles than there are users.
        if (i === (nextRoleStartIndex + roleIndex) % users.length) {
          assign = { role: role };
        }
      });

      return Object.assign({}, u, assign);
    });

    setUsers(newUsers);
  };

  const choiceAvailable = (step: ChoicesStep, choice: string, userID: number) => {
    if (!flowState[currentStepIndex]) return true;
    if (Object.values(flowState[currentStepIndex]).find((c) => c === choice) && step.uniquePicks) return false;
    if (flowState[currentStepIndex][userID] === choice) return false;
    return true;
  };

  const tick = (stepIndex: number) => {
    const step = flattenedSteps[stepIndex];

    if (isRoleRotationStep(step)) {
      rotateRoles();
      advanceOneStep(stepIndex);
      return;
    }

    if (isRoleRandomiserStep(step)) {
      randomiseRoles();
      advanceOneStep(stepIndex);
      return;
    }

    if (isFeedbackWordsStep(step)) {
      if (flowState[stepIndex] && users.every((u) => flowState[stepIndex][u.id])) {
        advanceOneStep(stepIndex);
      }
    }

    if (isGoToStep(step)) {
      setCurrentStepIndex(flattenedSteps.findIndex((s) => s.id === step.settings.stepID));
      return;
    }

    if (isMarkStepComplete(step)) {
      const skipStepIndex = flattenedSteps.findIndex((s) => s.id === step.settings.stepID);
      const roleAUser = users.find((u) => u.role == ROLE_A);
      if (roleAUser) {
        flowState[skipStepIndex] = flowState[skipStepIndex] || {};
        flowState[skipStepIndex][roleAUser.id] = true;
        advanceOneStep(stepIndex);
      }
      return;
    }

    if (isBranchStep(step)) {
      if (step.settings.conditional === "allCompleted") {
        const targetStepIndex = flattenedSteps.findIndex((s) => s.id === step.settings.stepID);
        const allComplete = users.every((u) => {
          return flowState[targetStepIndex][u.id];
        });

        if (allComplete) {
          advanceTwoSteps(stepIndex);
          return;
        } else {
          advanceOneStep(stepIndex);
          return;
        }
      }
    }

    if (!isChoicesStep(step) && !isContentStep(step) && !isFeedbackScoreStep(step)) {
      return;
    }

    if (!flowState[stepIndex]) return;

    const actionCount = countActions(step, Object.values(users).length);
    const doneCount = countDone(step, stepIndex, users, flowState);

    if (step.settings.clearDone && doneCount == actionCount) {
      flowState[stepIndex] = {};
    }

    if (doneCount === actionCount) {
      advanceOneStep(currentStepIndex);
    }
  };

  const doAction = useCallback(
    (action: ActionConsequence, userID: number, stepIndex: number, choice: string) => {
      flowState[stepIndex] = flowState[stepIndex] || {};
      flowState[stepIndex][userID] = choice || true;

      const newFlowState = Object.assign({}, flowState);
      setFlowState(newFlowState);

      if (typeof action === "number") {
        const newStepIndex = flattenedSteps.findIndex((s) => s.id === action);
        if (newStepIndex > -1) {
          setCurrentStepIndex(newStepIndex);
        }
      }
    },
    [flowState]
  );

  useEffect(() => {
    setPlayAudio(false);
  }, [currentStepIndex]);

  const setupAC = () => {
    if (!audioContext.audioContext) {
      const ac = new AudioContext({
        sampleRate: 44100,
        latencyHint: "playback",
      });

      ac.resume();

      const audioNodes = {
        audioContext: ac,
        gainNode: ac.createGain(),
        silentNode: ac.createGain(),
      };

      // A silent gain node to pipe the audio of the other players to.
      audioNodes.silentNode.gain.value = 0;

      setAudioContext(audioNodes);

      // Warm up the audio context. Playing this as soon as we create the audio context helps
      // get us permissions.
      const audio = new Audio(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2021-04-21/audio/sfx-arrive.mp3"
      );
      audio.volume = 0;
      audio.play();
    }
  };

  useEffect(() => {
    tick(currentStepIndex);
  }, [flowState]);

  const getExitURL = () => {
    if (stepID) {
      return `/session-flow/${params.flowID}/section/${sectionID}/step/${stepID}`;
    } else if (sectionID && sectionIndex && flow?.sections[sectionIndex].steps.length > 0) {
      return `/session-flow/${params.flowID}/section/${sectionID}/step/${flow?.sections[sectionIndex].steps[0].id}`;
    }
    return `/session-flow/${params.flowID}`;
  };

  const currentSection = flow?.sections.find((s) => s.title["en"] === currentStep.sectionTitle);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatNames = (names: string[]): string => {
    if (names.length === 0) return "yourself";
    if (names.length === 1) return names[0];
    if (names.length === 2) return `${names[0]} ${and[locale] || "and"} ${names[1]}`;
    if (names.length < 4) {
      return `${names[0]}, ${names[1]} ${and[locale] || "and"} ${names[2]}`;
    }
    return your_partners[locale] || "TRANSLATION_MISSING_YOUR_PARTNER";
  };

  const hasLocaleAudioFile = (step: ContentStep, locale: string) => {
    return (step.audioFile && step.audioFile[locale]) || step.audioFile["en"];
  };

  return (
    <FlowPreviewWrapper onClick={setupAC} data-testid={"flow-preview-wrapper"}>
      <PreviewToolbox>
        {/* @ts-ignore */}
        <ToolSection style={{ textAlign: "left" }}>
          {flow?.name} &gt; {currentStep?.sectionTitle} &gt; <b>{currentStepIndex + 1}.</b>{" "}
          {currentStep?.title[locale] || currentStep?.title["en"]}
        </ToolSection>
        <ToolSection>Time on step: {timeOnStep}</ToolSection>

        {currentSection?.enforceDuration && (
          <ToolSection>
            Time on section: {timeOnSection}/{currentSection?.durationSeconds}
            &nbsp; (Enforced)
          </ToolSection>
        )}
        <ToolSection style={{ textAlign: "center" }}>
          Number of Participants:{" "}
          <UsersCountInput
            type="number"
            value={userCount}
            min={"1"}
            max={"5"}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "") {
                setUserCount(3);
                return;
              } else {
                const val = parseInt(inputValue);
                if (val < 1 || val > 5) {
                  setUserCount(3);
                } else {
                  setUserCount(val);
                }
              }
            }}
            onBlur={() => {
              const newUsers = users.slice(0, userCount);
              for (let i = newUsers.length; i < userCount; i++) {
                newUsers.push({
                  id: i + 1,
                  name: nameAssignment(i),
                  role: roleAssignment(i),
                });
              }
              setUsers(newUsers);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </ToolSection>
        <ToolSection style={{ textAlign: "right" }}>
          <Link to={getExitURL()}>Exit Preview</Link>
        </ToolSection>
      </PreviewToolbox>

      <PreviewArea>
        {users.map((user) => {
          return (
            <UserView key={user.name}>
              {(() => {
                if (
                  !isContentStep(currentStep) &&
                  !isChoicesStep(currentStep) &&
                  !isIframeStep(currentStep) &&
                  !isFeedbackScoreStep(currentStep) &&
                  !isFeedbackWordsStep(currentStep)
                )
                  return null;

                const upperElements = [];
                const centerElements = [];
                const bottomElements = [];

                let role = user.role;
                if (currentStep.shareContent) {
                  role = EVERYONE_ELSE;
                }

                if (currentStep) {
                  const content = currentStep.content[role];

                  let content_text = textForLocale(content.text, locale);

                  if (isIframeStep(currentStep) && content_text === "") {
                    content_text = "Preparing next step...";
                  }

                  if (isFeedbackWordsStep(currentStep) && content_text === "") {
                    content_text = "Please provide feedback";
                  }

                  if (content_text && flow) {
                    upperElements.push(
                      <Prompt key="textprompt">
                        <LiquidRender
                          template={content_text}
                          // @ts-ignore
                          data={Object.assign(
                            {},
                            flow?.data[locale] || flow?.data["en"] || {},
                            { userResponses },
                            {
                              aName: users.find((u) => u.role === ROLE_A)?.name,
                              bName: users.find((u) => u.role === ROLE_B)?.name,
                              allPartners: users
                                .filter((u) => u.id !== user.id)
                                .map((u) => u.name)
                                .join(", "),
                              allParticipants: users
                                .map((u) => u.name)
                                .join(", "),

                              yourPartners: formatNames(users.filter((u) => u.id !== user.id).map((u) => u.name)),
                              YourPartners: capitalizeFirstLetter(
                                formatNames(users.filter((u) => u.id !== user.id).map((u) => u.name))
                              ),
                              yourName: user.name,
                              gatheringID: "abcd", // We use this as the seed for the shuffle function.
                              // Hardcoded here, but not in session-ui
                            }
                          )}
                        />
                      </Prompt>
                    );
                  }

                  if (isChoicesStep(currentStep) && currentStep) {
                    const content = currentStep.content[role];

                    const choices = content.choices || [];
                    if (choices.length > 0) {
                      centerElements.push(
                        <ChoicesList
                          key="choiceslist"
                          width={content.choiceListWidth || DEFAULT_CHOICE_LIST_WIDTH}
                          columns={content.columns || DEFAULT_CHOICE_COLUMNS}
                        >
                          {choices.map((choice: Choice) => {
                            return (
                              <StyledActionButton
                                key={textForLocale(choice.text, locale)}
                                primary
                                label={textForLocale(choice.text, locale)}
                                disabled={
                                  !choiceAvailable(currentStep, textForLocale(choice.text, locale) || "", user.id)
                                }
                                size="small"
                                style={{ height: content.buttonHeight || DEFAULT_CHOICE_BUTTON_HEIGHT }}
                                onClick={() =>
                                  doAction(
                                    choice.onClick || DEFAULT_CHOICE_ACTION,
                                    user.id,
                                    currentStepIndex,
                                    textForLocale(choice.text, locale) || ""
                                  )
                                }
                              />
                            );
                          })}
                        </ChoicesList>
                      );
                    }
                  }

                  if (isFeedbackScoreStep(currentStep) && currentStep) {
                    const descriptionLow = textForLocale(currentStep.content[role].descriptionLow, locale);

                    const descriptionHigh = textForLocale(currentStep.content[role].descriptionHigh, locale);

                    const maxVal = currentStep.content[role].maxValue;

                    const width = currentStep.content[role].choiceListWidth
                      ? currentStep.content[role].choiceListWidth
                      : 80;

                    const columns = currentStep.content[role].columns ? currentStep.content[role].columns : 10;

                    const content = currentStep.content[role];

                    const score = Array.from({ length: content.maxValue || 10 }, (_, i) => i + 1);
                    if (score.length > 0) {
                      centerElements.push(
                        <ScoreWrapper>
                          <ScoreDescription style={{ width: `${width}%` }}>
                            <small style={{ fontWeight: 400, color: "white" }}>{`1 = ${descriptionLow}`}</small>
                            <small style={{ fontWeight: 400, color: "white" }}>
                              {`${maxVal} = ${descriptionHigh}`}
                            </small>
                          </ScoreDescription>
                          <ChoicesList
                            key="scorelist"
                            width={width || DEFAULT_CHOICE_LIST_WIDTH}
                            columns={columns || DEFAULT_CHOICE_COLUMNS}
                          >
                            {score.map((i) => {
                              return (
                                <StyledActionButton
                                  key={i}
                                  primary
                                  label={i.toString()}
                                  size="small"
                                  style={{ height: content.buttonHeight || DEFAULT_CHOICE_BUTTON_HEIGHT }}
                                  onClick={() => {
                                    doAction("done", user.id, currentStepIndex, i.toString());
                                  }}
                                />
                              );
                            })}
                          </ChoicesList>
                        </ScoreWrapper>
                      );
                    }
                  }

                  if (isFeedbackWordsStep(currentStep) && currentStep) {
                    centerElements.push(
                      <Card width="medium" background="light-1">
                        <CardBody
                          pad={{ horizontal: "small", bottom: "small", top: "small" }}
                          style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                        >
                          <TextInput placeholder="word1, word2, word3" />
                          <ActionButton
                            primary
                            label="Submit"
                            size="small"
                            onClick={() => {
                              doAction("done", user.id, currentStepIndex, "done");
                            }}
                          />
                        </CardBody>
                      </Card>
                    );
                  }

                  if (content.actions.length > 0) {
                    bottomElements.push(
                      <ActionButtons key="actionbuttons" style={{ marginBottom: "10px", alignSelf: "flex-end" }}>
                        {content.actions.map((action, index) => {
                          return (
                            !flowState[currentStepIndex]?.[user.id] && (
                              <ActionButton
                                style={{ marginRight: "10px" }}
                                key={index}
                                label={textForLocale(action.buttonText, locale)}
                                size="small"
                                onClick={() =>
                                  doAction(
                                    action.onClick,
                                    user.id,
                                    currentStepIndex,
                                    textForLocale(action.buttonText, locale) || ""
                                  )
                                }
                              />
                            )
                          );
                        })}
                      </ActionButtons>
                    );
                  }
                }

                if (hasLocaleAudioFile(currentStep as ContentStep, locale)) {
                  upperElements.push(
                    <MediaPlayer
                      key={"mediaplayer" + currentStep.audioFile[locale]?.url || currentStep.audioFile["en"]?.url}
                      audioContext={audioContext.audioContext}
                      audioFileURL={currentStep.audioFile[locale]?.url || currentStep.audioFile["en"]?.url || ""}
                      playing={playAudio}
                      initialCurrentTime={audioCurrentTime}
                      gainNode={user.role === ROLE_A ? audioContext.gainNode : audioContext.silentNode}
                      onAudioLoaded={() => {
                        setPlayAudio(true);
                      }}
                      onAudioDone={() => {
                        doAction("done", user.id, currentStepIndex, "done");
                      }}
                      onPlay={() => setPlayAudio(true)}
                      onPause={() => setPlayAudio(false)}
                      onSetCurrentTime={(d) => setAudioCurrentTime(d)}
                    />
                  );
                }

                const waitDuration = scaledDuration(
                  flow?.durationScalingBase || 2,
                  users.length,
                  currentStep.content[role].actionTiming
                );

                return (
                  <>
                    <UpperArea>{upperElements}</UpperArea>
                    <CenterArea>{centerElements}</CenterArea>
                    <BottomArea>
                      {timeOnStep < waitDuration ? (
                        <WaitSuggestion locale={locale} waitDuration={waitDuration} setTimeOnStep={setTimeOnStep} />
                      ) : (
                        bottomElements
                      )}
                    </BottomArea>
                  </>
                );
              })()}
            </UserView>
          );
        })}
        <StateBox>
          <pre>{JSON.stringify(flowState, null, 2)}</pre>
          <pre>{JSON.stringify(users, null, 2)}</pre>
        </StateBox>
      </PreviewArea>
    </FlowPreviewWrapper>
  );
};

export default FlowPreview;
